import React from 'react'
import portfolioImg from "../../images/portfolioImg.png"
import { Link } from 'react-router-dom'
export const Portfolio = () => {
  return (
    <div className='portfolioSec'>
         <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="enrolled-content mb-30">
                <div className="section-title">
                  <h1>Unlock Your <span>Decentralized Trading</span> Dashboard</h1>
                  <p> Your gateway to the decentralized economy </p>
                </div>
                <Link to="/portfolio/connect" className="default-btn">Go to dashboard</Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div data-speed="0.05" data-revert="true" className="enrolled-img-two mb-30">
                <img src={portfolioImg} alt="Enrolled" />
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
