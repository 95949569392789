import React from 'react'
import profile from "../../../images/profile.png";
import editIco from "../../../images/editIco.svg";
import eyeICO from "../../../images/eyeICO.svg";
import arrowUp from "../../../images/arrowUp.svg";
import btc from "../../../images/btc.png";

export const PortfolioPage = () => {
  return (
    <div className='portfolioPage'>
        <div className="row">
            <div className="col-md-6 mb-3">
                <div className="portCard">
                    <div className="head">Add Wallets</div>
                    <div className="myPortForm">
                        <div className='mb-3'>
                            <div className="titleRow">
                                <div className="name">Meta Mask</div>
                                <div className="ico"><img src={editIco} alt="" /></div>
                            </div>
                            <div className="profileRow">
                                <div className="logo"><img src={profile} alt="" /></div>
                                <div>
                                    <div className="title">My Portfolio</div>
                                    <div className="amount">$3,153,081.03</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="titleRow">
                                <div className="name">Trust Wallet</div>
                                <div className="ico"><img src={editIco} alt="" /></div>
                            </div>
                            <div className="profileRow">
                                <div className="logo"><img src={profile} alt="" /></div>
                                <div>
                                    <div className="title">My Portfolio</div>
                                    <div className="amount">$3,153,081.03</div>
                                </div>
                            </div>
                        </div>

                        <div className="addAccount">+ Add New account</div>

                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="portCard">
                    <div className="head">My Account</div>
                    <div className="myPortForm">
                        <div className="amountRow">
                            <div>
                                <div className="pRow">
                                    <div className="imgDiv">
                                        <img src={profile} alt="" />
                                    </div>
                                    My Main Portfolio
                                </div>
                                <div className="amount">$3,153,081.03</div>
                                <div className="amountSm">+ $3,153,081.03 (24h)</div>
                            </div>
                            <div>
                                <img src={eyeICO} alt="" />
                            </div>
                        </div>
                        <div className="r2">
                            <div className="profiltCard">
                                <div className="title">All-time profit</div>
                                <div className="amount">+ $6,232.30</div>
                                <div className="perc"><img src={arrowUp} alt="" /> 0.19%</div>
                            </div>
                            <div className="performerCard">
                                <div className="title">All-time profit</div>
                                <div className="currency">
                                    <img src={profile} alt="" />
                                    BTC
                                </div>
                                <div className="perc">+ $6,590.52 <img src={arrowUp} alt="" /> <span>0.21%</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-3">
                <div className="portCard">
                    <div className="head">Portfolio</div>
                    <div className="myPortForm">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="balanceM">
                                    <div className="title">Your Balance in USD</div>
                                    <div className="balance">$ 0.00</div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="tokenMain">
                                    <div className="tokenRow">
                                        <div className="coin">
                                            <div className="logo">
                                                <img src={btc} alt="" />
                                            </div>
                                            Bitcoin
                                        </div>
                                        <div>
                                            <div className="amount">0.00</div>
                                            <div className="usd">$43,702.96</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
