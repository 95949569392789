import React from 'react'
import logo from "../images/logo.png"
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import userAction from "../redux/users/action";

export const Navbar = () => {
  const userStore = useSelector((state) => state.userReducer);
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(setUser({}))
    localStorage.clear()
    navigate("/")
  }

  return (
    <div className="navbar-area ">
    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <NavLink to={"/"} className="navbar-brand" href="#">
            <img src={logo} alt="" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/"> Home </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio"> Portfolio </NavLink>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/#services-courses"> Courses </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#about"> About </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#testimonial"> Testimonial </a>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/survey"> Survey </NavLink>
              </li> */}
              {userStore?.users?.user?.wallet &&
              <li className="nav-item">
                <NavLink className="nav-link" to="/post"> Post </NavLink>
              </li>
              }
              <li className="nav-item">
                {userStore?.users?.user?.name ?
                    <div className="logoutDrop">
                      <button className='default-btn name '>
                        {userStore?.users?.user?.name.length >12 ?userStore?.users?.user?.name?.slice(0,12)+" ..." : userStore?.users?.user?.name}
                      </button>
                      <div className="logout" onClick={logout}>Logout</div>
                    </div>
                :
                <>
                <div className="d-flex">
                  <NavLink className="nav-link me-0" to="/signup"> 
                      <button className='default-btn'>Register</button>
                  </NavLink>
                  <NavLink className="nav-link" to="/login"> 
                      <button className='default-btn'>Login</button>
                  </NavLink>
                </div>
                </>
                }
              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  )
}
