import React from 'react'
import walletImg from "../../images/walletImg.png"
import star from "../../images/star.png"
import { useNavigate } from 'react-router-dom'
export const ConnectPage = () => {
    const navigate = useNavigate()
    
  return (
    <div className='ConnectPage'>
        <div className="col1">
            <div className="goBack" onClick={()=>navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="imgDiv">
                <img src={walletImg} alt="wallet" />
            </div>
        </div>
        <div className="col2">
            <img className='star1' src={star} alt="" />
            <img className='star2' src={star} alt="" />
            <div className="content">
                <h3>Which wallet you want to connect</h3>
                <div className="text-center mb-3">
                    <button className="connectBtn">Connect with meta mask</button>
                </div>
                <div className="text-center mb-3">
                    <button className="connectBtn">Connect with trust wallet</button>
                </div>
                <div className="text-center">
                    <button className="connectBtn">Connect with exchange</button>
                </div>
            </div>
        </div>
    </div>
  )
}
