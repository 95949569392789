import React from 'react'
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { NavbarAdmin } from '../../global/navbar/navbar';
import Sidebar from '../../global/sidebar/sidebar';
import { DataInfo } from '../../global/dataInfo';
import { NavbarPorfoilio } from './porfolioNavbar';
import SidebarPortfolio from './porfolioSidebar';
import { PortfolioPage } from './portfolioPage';

export const PortfolioDashboard = () => {
  return (
    <>
       <div id="wrapper" className='portfolioDashboard'>
            <SidebarPortfolio />
              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <NavbarPorfoilio />
                  <PortfolioPage />
                  {/* <Routes>
                    <Route exact={true} path="/admin/datainfo" element={<DataInfo />} />
                  </Routes> */}
                  
              </div>
          </div>
        </div>
    </>
  )
}
